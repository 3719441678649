body::-webkit-scrollbar {
  display: none;
}

.plate {
  position: fixed;
  scale: 2.5;
  left: -10rem;
  top: 11.5rem;
  z-index: 1;
}

.plate_bg {
  position: fixed;
  top: 0rem;
  z-index: 0;
}

.itemholder {
  position: fixed;
  top: 16rem;
  left: -4rem;
  color: #ee540d;
  z-index: 3;
}

.itemholder i {
  transition: font-size 0.5s ease-out;
  z-index: 10;
}

.menu_bg {
  position: fixed;
  top: 6.5rem;
  right: 12rem;
  scale: 1.5 1;
  z-index: 3;
}

.inner_bg {
  position: fixed;
  height: 65%;
  width: 60%;
  inset: 0;
  margin: 120px 75px auto auto;
  z-index: 4;
  mix-blend-mode: difference;
  opacity: 0.25;
}

.menu_select {
  position: fixed;
  left: 8rem;
  top: 14rem;
  z-index: 2;
}

.background_img {
  position: fixed;
  scale: 1.75 1;
  transform-origin: center;
  z-index: -2;
}

@keyframes vibrate {
  0% {
    transform: rotate(25deg);
  }
  25% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(25deg);
  }
  75% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
