@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

.menufolder {
  position: fixed;
  top: 6rem;
  left: 30rem;
  height: 400px;
  width: 750px;
  z-index: 4;
}

.menutype {
  color: #ee540d;
  font-family: "Nunito", sans-serif;
  font-size: 100px;
  letter-spacing: 5px;
  text-shadow: 2px 3px 10px black;
}

.menudetails {
  display: grid;
  grid-template-columns: 1.75fr 0.25fr;
  border-left: 5px solid #ee540d;
  margin-bottom: 25px;
  padding-left: 10px;
  padding-bottom: 15px;
  background-image: linear-gradient(to right, #ee540da8, #ecbba4a8);
  line-height: 2px;
  cursor: pointer;
}

.itemname,
.itemprice {
  font-size: 25px;
  font-family: "Staatliches", cursive;
  letter-spacing: 1px;
}

.itemdetail {
  font-size: 15px;
  color: #383737;
}
