.locationstate {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0rem 2rem 0rem 0rem;
  padding: 0.75rem 0rem 0.75rem 0.5rem;
}

.locationcity {
  font-size: 1rem;
  margin: 0rem 3rem 0rem 0.5rem;
  padding: 0.25rem 0rem 0.25rem 1rem;
}

.locationstate,
.locationcity {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  direction: ltr;
}

.locationstate:hover,
.locationcity:hover {
  background-color: aliceblue;
  border-radius: 0.5rem;
}
