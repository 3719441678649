.cursor {
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  border-radius: 50%;
  mix-blend-mode: difference;
  z-index: -1;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.navlink:hover ~ .cursor {
  transform: scale(1.5);
}

.logoname:hover ~ nav .cursor {
  background-color: #ee540d;
}
