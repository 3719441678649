.mapbutton {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  margin: 0.5rem;
  font-size: 25px;
}

.back {
  left: 0px;
}

.dest {
  right: 0px;
  bottom: 10px;
}

.src {
  right: 0px;
  bottom: 75px;
}

.mapbutton:hover {
  border-color: white;
  background-color: black;
  color: white;
}
