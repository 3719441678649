@media screen and (max-width: 767px) {
  .cheflistholder.show_bg {
    transform: translateX(0%);
    width: 45%;
    z-index: 5;
  }

  .cheflistholder {
    transform: translateX(-110%);
  }

  .chefnameholder {
    font-size: 2.5vw;
  }
}
