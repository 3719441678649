.cheflistholder {
  height: 75%;
  width: 17.5%;
  position: absolute;
  z-index: 1;
  top: 17.5%;
  transition: all 0.5s;
}

.chefnameholder {
  height: 15%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  font-family: "Metrophobic", sans-serif;
  pointer-events: none;
  font-size: 1.05vw;
}

.first {
  top: 12.5%;
}

.second {
  top: 37.5%;
  width: 107.5%;
  font-weight: 800;
}

.third {
  top: 62.5%;
}
