.searchbar {
  position: relative;
  margin: 7.5rem 67.5rem 1rem auto;
  box-shadow: 1.5px 1.5px 15px black;
  height: 2rem;
  width: 2rem;
  display: flex;
  border-radius: 10rem;
  border: 2px solid black;
  overflow: hidden;
  font-size: 1.25em;
  transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  padding: 0.25rem;
  float: right;
}

.searchtag {
  background-color: white;
  padding: 0.25em 1em;
  border: none;
  outline: none;
  position: absolute;
  z-index: 2;
  inset: 0;
  opacity: 0;
}

.searchbtn {
  border: none;
  background: transparent;
  font-size: 1.25rem;
  z-index: 3;
  cursor: pointer;
  position: absolute;
  right: 0.25rem;
  top: 0.5rem;
}

.searchbefore {
  position: absolute;
  left: 2.5rem;
  top: 8rem;
  z-index: 5;
  font-size: 1.25rem;
  z-index: 0;
}
